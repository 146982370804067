
import { computed, defineComponent, ref } from "vue";
import "moment/locale/es";
import type { ElDrawer } from "element-plus";
import moment from "moment";
import "moment/locale/es";
import { titulacionesTodas } from "@/core/data/titulaciones";

export default defineComponent({
  props: {
    displayFicha: {
      type: Boolean,
      required: true,
    },
    profesional: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const drawerRef = ref<InstanceType<typeof ElDrawer>>();

    const drawerFicha = computed({
      get: () => props.displayFicha,
      set: (value) => emit("update:displayFicha", value),
    });

    return {
      drawerFicha,
      drawerRef,
      titulacionesTodas,
      titulacion: computed(() => {
        const titulacionesProfesional = props.profesional.titulacion.map((t) =>
          titulacionesTodas.filter((a) => a.id == t)
        );
        return (
          titulacionesProfesional
            ?.map((t) => t.map((r) => r.text))
            .join(", ") || ""
        );
      }),
      fechaNacimiento: computed(() => {
        return moment(props.profesional.fechaNacimiento).format("DD/MM/YYYY");
      }),
    };
  },
});
