import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "text-lg-center pt-3" }
const _hoisted_10 = { class: "dialog-footer" }
const _hoisted_11 = { class: "text-lg-center pt-3" }
const _hoisted_12 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_download_excel = _resolveComponent("download-excel")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_DrawerFichaProfesional = _resolveComponent("DrawerFichaProfesional")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tabla.serviciosProfesionalesListado.titulo")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_download_excel, {
          data: _ctx.profesionalesListado,
          labels: _ctx.csvLabels,
          fields: _ctx.csvFields,
          name: _ctx.csvName
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", _hoisted_6, [
              _createElementVNode("i", {
                class: "fas fa-file-excel fs-3",
                title: _ctx.$t('tabla.serviciosProfesionalesListado.boton.imprimir')
              }, null, 8, _hoisted_7)
            ])
          ]),
          _: 1
        }, 8, ["data", "labels", "fields", "name"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_el_table, {
        data: _ctx.profesionalesListado,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.nombre'),
            sortable: "",
            "sort-method": _ctx.sortNombreApellidos,
            "min-width": "150"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.profesional?.nombre +
              " " +
              scope.row.profesional?.apellidos), 1)
            ]),
            _: 1
          }, 8, ["label", "sort-method"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.telefono'),
            sortable: "",
            width: "120"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.profesional?.telefono), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.email'),
            sortable: "",
            "min-width": "150"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.profesional?.email), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.titulacion'),
            sortable: "",
            "min-width": "150"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.profesional?.titulacion
                .map((t) => _ctx.titulacionesTodas.filter((a) => a.id == t))
                ?.map((t) => t.map((r) => r.text))
                .join(", ") || ""), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            label: 
            _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.fechaInicio')
          ,
            prop: "fechaInicio",
            formatter: _ctx.toLocaleDateString,
            sortable: "",
            "min-width": "130"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            label: _ctx.$t('tabla.serviciosProfesionalesListado.cabecera.fechaFin'),
            prop: "fechaFin",
            formatter: _ctx.toLocaleDateString,
            sortable: "",
            "min-width": "120"
          }, null, 8, ["label", "formatter"]),
          _createVNode(_component_el_table_column, {
            width: "240",
            fixed: "right"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_button, {
                size: "small",
                type: "text",
                onClick: ($event: any) => (_ctx.verFichaProfesional(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosProfesionalesListado.fila.ver")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "text",
                onClick: ($event: any) => (_ctx.avisoCambioFechasProfesional(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosProfesionalesListado.fila.modificarFechas")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_el_button, {
                size: "small",
                type: "text",
                onClick: ($event: any) => (_ctx.eliminarProfesional(scope.$index, scope.row))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("tabla.serviciosProfesionalesListado.fila.eliminar")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"]),
      _createVNode(_component_DrawerFichaProfesional, {
        "before-close": _ctx.drawerCerrar,
        displayFicha: _ctx.drawerFicha,
        profesional: _ctx.profesionalSeleccionado!
      }, null, 8, ["before-close", "displayFicha", "profesional"]),
      _createVNode(_component_el_dialog, {
        center: "",
        modelValue: _ctx.dialogVisible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialogVisible) = $event)),
        title: _ctx.$t('dialogs.cancelarProfesional.title'),
        width: "30%"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_10, [
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogVisible = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.cancelarProfesional.cancelButton")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.cancelarProfesional
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.cancelarProfesional.okButton")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("dialogs.cancelarProfesional.body", {
              nombreCompleto: _ctx.nombreCompleto,
            })), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.fechaFinServicio,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fechaFinServicio) = $event)),
              type: "date",
              "disabled-date": _ctx.disabledDate,
              format: "DD/MM/YYYY",
              placeholder: _ctx.$t('dialogs.cancelarProfesional.seleccionaFecha'),
              "default-value": _ctx.profesionalSeleccionadoFechaFin
            }, null, 8, ["modelValue", "disabled-date", "placeholder", "default-value"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_el_dialog, {
        center: "",
        modelValue: _ctx.dialogVisibleModificarFechas,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogVisibleModificarFechas) = $event)),
        title: _ctx.$t('dialogs.modificarFechasProfesional.title'),
        width: "30%"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_12, [
            _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialogVisibleModificarFechas = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.modificarFechasProfesional.cancelButton")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.modificarFechasProfesional
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialogs.modificarFechasProfesional.okButton")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("dialogs.modificarFechasProfesional.body", {
              nombreCompleto: _ctx.nombreCompleto,
            })), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.fechasModificacionProfesional,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fechasModificacionProfesional) = $event)),
              type: "daterange",
              "disabled-date": _ctx.disabledDate,
              "start-placeholder": 
              _ctx.$t('dialogs.modificarFechasProfesional.fechaDeInicio')
            ,
              "end-placeholder": 
              _ctx.$t('dialogs.modificarFechasProfesional.fechaDeFin')
            ,
              "default-value": [
              _ctx.profesionalSeleccionadoFechaInicio,
              _ctx.profesionalSeleccionadoFechaFin,
            ],
              format: "DD/MM/YYYY",
              "range-separator": "a"
            }, null, 8, ["modelValue", "disabled-date", "start-placeholder", "end-placeholder", "default-value"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "title"])
    ])
  ]))
}