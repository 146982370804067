
import DrawerFichaProfesional from "@/components/servicios/DrawerFichaProfesional.vue";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { titulacionesTodas } from "@/core/data/titulaciones";
import {
  ProfesionalListado,
  ServicioListadoModule,
  ServicioProfesionalListado,
} from "@/store/modules";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "profesionales-listado",
  components: { "download-excel": JsonExcel, DrawerFichaProfesional },
  props: {
    profesionales: Object as () => ServicioProfesionalListado[] | null,
    inicioPrevisto: Date,
    finPrevisto: Date,
    comarcaId: String,
    servicioId: String,
  },

  setup(props) {
    const { t } = useI18n();

    const router = useRouter();
    const csvLabels = {
      nombre: t("csv.listados.profesionales.nombre"),
      apellidos: t("csv.listados.profesionales.apellidos"),
      telefono: t("csv.listados.profesionales.telefono"),
      email: t("csv.listados.profesionales.email"),
      titulacion: t("csv.listados.profesionales.titulacion"),
    };
    const route = useRoute();
    const token = route.params.id;

    const store = useStore();
    const module = getModule(ServicioListadoModule, store);

    const csvFields = [
      "nombre",
      "apellidos",
      "telefono",
      "email",
      "titulacionText",
    ];
    const csvName =
      "serviciosProfesionalesListado" + new Date().getTime() + ".xlsx";

    const drawerFicha = ref(false);

    const sortNombreApellidos = function (a, b) {
      let primero = a.nombre + " " + a.apellidos;
      let segundo = b.nombre + " " + b.apellidos;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };

    const loadingAsignarProfesional = ref(false);

    const profesionalSeleccionado = ref<ProfesionalListado | null>(null);
    const error = computed(() => module.errors);
    return {
      error,
      titulacionesTodas,
      profesionalesListado: computed(
        () =>
          props.profesionales?.map((row) => {
            return {
              ...row,
              titulacionText:
                row.titulacion
                  ?.map((t) => titulacionesTodas.filter((a) => a.id == t))
                  ?.map((t) => t.map((r) => r.text))
                  .join(", ") || "",
            };
          }) ?? []
      ),
      csvLabels,
      csvFields,
      csvName,
      drawerFicha,
      profesionalSeleccionado,
      loadingAsignarProfesional,
      drawerCerrar: () => {
        drawerFicha.value = false;
      },
      verFichaProfesional: (index, row) => {
        profesionalSeleccionado.value = row;
        drawerFicha.value = true;
      },

      asignarProfesional: (index, row) => {
        const fechaInicioCompleta =
          moment(props.inicioPrevisto).format("dddd") +
          " " +
          moment(props.inicioPrevisto).format("LL");
        const fechaFinCompleta =
          moment(props.finPrevisto).format("dddd") +
          " " +
          moment(props.finPrevisto).format("LL");

        const nombreCompleto = row.nombre + " " + row.apellidos;

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        swalWithBootstrapButtons
          .fire({
            title: t("dialogs.asignarProfesional.title"),
            width: 800,
            html: t("dialogs.asignarProfesional.body", {
              nombreCompleto,
              fechaInicioCompleta,
              fechaFinCompleta,
            }),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: t("dialogs.asignarProfesional.okButton"),
            cancelButtonText: t("dialogs.cancelButton"),
            reverseButtons: true,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const idServicio = props.servicioId || token;
              loadingAsignarProfesional.value = true;
              try {
                await module
                  .asignarProfesional({
                    comarcaId: props.comarcaId as string,
                    idServicio: idServicio as string,
                    idProfesional: row.id,
                  })
                  .then(() => {
                    if (!module.errors?.error) {
                      Swal.fire({
                        text: t(
                          "formularios.servicioDetalle.asignar.mensaje.exito"
                        ),
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: t("dialogs.okButton"),
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                      }).then(() => {
                        router.go(0);
                      });
                    } else {
                      swalWithBootstrapButtons.fire(
                        t("dialogs.error"),
                        t("dialogs.asignarProfesional.errorText"),
                        "error"
                      );
                    }
                  });
              } catch (err) {
                Swal.fire({
                  text: t(
                    "formularios.profesionalesDisponiblesListado.asignar.error"
                  ),
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              } finally {
                loadingAsignarProfesional.value = false;
              }
            }
          });
      },
      sortNombreApellidos,
    };
  },
});
