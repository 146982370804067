
import FamiliaReadOnly from "@/components/familia/FamiliaReadOnly.vue";
import DrawerHorario from "@/components/servicios/DrawerHorario.vue";
import ProfesionalesTablaAsignados from "@/components/servicios/TablaProfesionalesAsignados.vue";
import ProfesionalesTablaDisponibles from "@/components/servicios/TablaProfesionalesDisponibles.vue";
import EstadoTag from "@/components/widgets/EstadoTag.vue";
import comarcas from "@/core/data/comarcas";
import { nombreIdioma } from "@/core/data/idiomas";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import lugaresPrestacion from "@/core/data/lugaresPrestacion";
import { dias, horarios } from "@/core/data/tiemposFamilias";
import tratamientos from "@/core/data/tratamientos";
import vehiculos from "@/core/data/vehiculos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ServicioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import {
  AuthModule,
  ProfesionalAsignado,
  ServicioListadoModule,
  ServiciosProgramadosModule,
} from "@/store/modules";
import { ArrowDown } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";
import { Disponibilidad, Reserva } from "../../store/models";

interface Servicio {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  fechaNacimiento: Date;
  lugarPrestacion: string;
  lugarEspacioColectivo?: string | null;
  otrosEspacioColectivo?: string | null;
  direccion: string;
  descripcion: string;
  inicioPrevisto: Date;
  finPrevisto: Date;
  tiempos: Disponibilidad[];
  familiaId: string;
  comarcaId: string;
  fechas: Array<string> | [];
}

export default defineComponent({
  name: "listados-servicio-detalle",
  components: {
    ErrorMessage,
    Page,
    DrawerHorario,
    ProfesionalesTablaAsignados,
    ProfesionalesTablaDisponibles,
    ArrowDown,
    EstadoTag,
    FamiliaReadOnly,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id;

    const store = useStore();
    const module = getModule(ServicioListadoModule, store);
    const serviciosProgramadosModule = getModule(
      ServiciosProgramadosModule,
      store
    );
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const cargaDetalle = ref(false);
    const cargaListadoProfesionales = ref(false);
    const crearServicioProgramado = ref(false);

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId: comarcaId, id: token as string });
      } catch (err) {
        Swal.fire({
          text: t("formularios.servicioDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const datosServicio = computed(() => module.servicio);
    const profesionalesAsignados = ref(false);
    const dialogVisible = ref(false);
    const dialogLoading = ref(false);
    const dialogSelected = ref<string>();

    const profesionalesAsignadosFilas = ref<ProfesionalAsignado[]>([]);

    const vehiculoPropio = ref<string>();
    const disponeTitulacion = ref<boolean>();
    const idioma = ref<Array<string>>();
    const experienciaCuidadoMenores = ref<string>();
    const disponiblidadEspecial = ref<Array<string>>();
    const verSoloDisponibles = ref<boolean>(false);

    const profesionalesFiltrados = computed(() => {
      /* INFO: se filtrarían los profesionales asignados del listado de los profesionales para el servicio. No se aplica porque el profesional asignado, en el listado ya aparecería como no disponible, además de los mensajes de confirmación para que el gestor de la comarca contacte con el profesional antes de asignarlo al servicio.
      profesionalesListado.value.filter((pl) =>
        profesionalesAsignadosFilas.value.some((pa) => pa.id != pl.id)
      );*/
      return profesionalesListado.value
        .filter((p) => {
          if (vehiculoPropio.value && p.vehiculo != vehiculoPropio.value) {
            return false;
          }
          if (
            idioma.value &&
            idioma.value.length > 0 &&
            !idioma.value.every((i) =>
              p.idiomas.some((j) => j.nombreIdioma === i)
            )
          ) {
            return false;
          }
          if (
            typeof disponeTitulacion.value !== "undefined" &&
            p.disponeTitulacion != disponeTitulacion.value
          ) {
            return false;
          }
          if (
            typeof experienciaCuidadoMenores.value !== "undefined" &&
            experienciaCuidadoMenores.value !== "" &&
            p.experienciaCuidadoMenores != experienciaCuidadoMenores.value
          ) {
            return false;
          }
          if (
            disponiblidadEspecial.value &&
            disponiblidadEspecial.value.length > 0 &&
            !disponiblidadEspecial.value.every((d) => p[d] == true)
          ) {
            return false;
          }
          if (verSoloDisponibles.value === true && !p.disponible) {
            return false;
          }
          return true;
        })
        .sort((a, b) => +b.disponible - +a.disponible);
    });

    const estado = ref<string>();

    const error = computed(() => module.errors);
    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    watch(datosServicio, () => {
      if (!datosServicio.value) return;

      estado.value = datosServicio.value.estado;

      profesionalesAsignados.value = Boolean(
        datosServicio.value?.profesionalesAsignados?.length
      );

      profesionalesAsignadosFilas.value =
        datosServicio.value?.profesionalesAsignados ?? [];
      const menor = datosServicio.value.menores[0];

      resetForm({
        values: {
          tratamiento: menor.tratamiento,
          nombre: menor.nombre,
          apellidos: menor.apellidos,
          fechaNacimiento: menor.fechaNacimiento,
          lugarPrestacion: datosServicio.value.lugarPrestacion,
          lugarEspacioColectivo: datosServicio.value.lugarEspacioColectivo,
          otrosEspacioColectivo: datosServicio.value.otrosEspacioColectivo,
          direccion: datosServicio.value.direccion,
          descripcion: datosServicio.value.descripcion,
          inicioPrevisto: datosServicio.value.inicioPrevisto,
          finPrevisto: datosServicio.value.finPrevisto,
          tiempos: datosServicio.value.tiempos.map((obj) => ({ ...obj })),
          familiaId: datosServicio.value.familiaId,
          comarcaId: datosServicio.value.comarcaId,
          fechas: datosServicio.value.fechas ?? [],
        },
      });

      setCurrentPageBreadcrumbs(menor.nombre + " " + menor.apellidos ?? "", [
        t("menu.servicios"),
        {
          title:
            estado.value == "APROBADO"
              ? t("menu.serviciosIndividuales")
              : t("menu.serviciosSolicitados"),
          path:
            estado.value == "APROBADO"
              ? "/listados/serviciosprestados"
              : "/solicitudes/servicios",
        },
      ]);
    });

    const schema = Yup.object({
      tratamiento: Yup.string().required(),
      nombre: Yup.string().required(),
      apellidos: Yup.string().required(),
      fechaNacimiento: Yup.date().required(),
      lugarPrestacion: Yup.string().required(),
      lugarEspacioColectivo: Yup.string().nullable(),
      otrosEspacioColectivo: Yup.string().nullable(),
      direccion: Yup.string().required(),
      descripcion: Yup.string().required(),
      inicioPrevisto: Yup.date().required(),
      finPrevisto: Yup.date().required(),
      familiaId: Yup.string().required(),
      comarcaId: Yup.string().required(),
      fechas: Yup.array().notRequired(),
      tiempos: Yup.array()
        .of(
          Yup.object({
            dias: Yup.array().ensure().min(1),
            horas: Yup.array().ensure().min(1),
          })
        )
        .ensure()
        .min(1)
        .test(
          "elemento-completo",
          t("listados.servicioDetalle.tiempos.errorFaltaDiaHora"),
          (value) =>
            !value?.some((ele) => !ele?.dias?.length || !ele?.horas?.length)
        ),
    });

    const { resetForm, handleSubmit, errors } = useForm<Servicio>({
      validationSchema: schema,
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: fechaNacimiento } = useField("fechaNacimiento");
    const { value: lugarPrestacion } = useField("lugarPrestacion");
    const { value: lugarEspacioColectivo } = useField("lugarEspacioColectivo");
    const { value: otrosEspacioColectivo } = useField("otrosEspacioColectivo");
    const { value: direccion } = useField("direccion");
    const { value: descripcion } = useField("descripcion");
    const { value: inicioPrevisto, setValue: setInicioPrevisto } =
      useField<Date>("inicioPrevisto");
    const { value: finPrevisto, setValue: setFinPrevisto } =
      useField<Date>("finPrevisto");
    const { value: tiempos } = useField<Disponibilidad[]>("tiempos");
    const { value: familiaId } = useField("familiaId");
    const { value: comarcaIdV } = useField<string>("comarcaId");

    const { value: fechas } = useField<Reserva[]>("fechas");

    const drawer = ref(false);
    const busquedaProfesional = ref(false);
    const fechasModificadas = ref(false);

    const profesionalesListado = computed(() => module.profesionales);
    const familia = computed(() => datosServicio.value?.familia);

    const familiaTratamiento = computed(() => {
      const result = tratamientos.find(
        (tr) => tr.id === familia.value?.tratamiento
      );

      return result ?? tratamientos[3];
    });

    return {
      ServicioEstado,
      drawerAceptar: (fecha) => {
        fechas.value.push(fecha);
        drawer.value = false;
        fechasModificadas.value = true;
      },
      drawerCerrar: () => {
        drawer.value = false;
      },
      openServicioColectivoPicker: async () => {
        dialogVisible.value = true;
        dialogLoading.value = true;
        try {
          await serviciosProgramadosModule.fetchListado(
            comarcaIdV.value as string
          );
        } catch (err) {
          Swal.fire({
            text: t("formularios.serviciosProgramadosListado.cargar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          dialogLoading.value = false;
        }
      },
      closeServicioColectivoPicker: async () => {
        dialogVisible.value = false;
        dialogSelected.value = undefined;
      },
      asignarServicioColectivo: () => {
        const comarcaId = comarcaIdV.value as string;
        const idServicio = token as string;
        const idServicioColectivo = dialogSelected.value as string;

        if (!comarcaId || !idServicio || !idServicioColectivo) return;

        return module
          .asignarServicioColectivo({
            comarcaId,
            idServicio,
            idServicioColectivo,
          })
          .then(() => {
            dialogVisible.value = false;
            return Swal.fire({
              text: t("dialogs.reasignarAServicioColectivo.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          })
          .then(() => {
            router.replace(
              "/listados/servicios-programados/" + idServicioColectivo
            );
          })
          .catch(() => {
            Swal.fire({
              text: t("dialogs.reasignarAServicioColectivo.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      cambiarEstado: (functionName) => {
        const comarcaId = comarcaIdV.value as string;
        const servicioId = token;

        return module[functionName]({
          comarcaId: comarcaId,
          servicioId: servicioId as string,
        })
          .then(() => {
            return Swal.fire({
              text: t("dialogs.cambiarEstadoServicio.exito"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => router.push("/solicitudes/servicios"));
          })
          .catch(() => {
            Swal.fire({
              text: t("dialogs.cambiarEstadoServicio.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      },
      dialogVisible,
      dialogLoading,
      dialogSelected,
      dialogData: computed(() =>
        serviciosProgramadosModule.servicios.filter((servicio) => {
          return (
            servicio.inscripcionAbierta &&
            new Date(servicio.servicio.finPrevisto) > new Date()
          );
        })
      ),
      familia,
      familiaTratamiento,
      fechasModificadas,
      profesionalesAsignados,
      nombreIdioma,
      vehiculos,
      profesionalesListado,
      profesionalesFiltrados,
      vehiculoPropio,
      idioma,
      disponeTitulacion,
      experienciaCuidadoMenores,
      disponiblidadEspecial,
      verSoloDisponibles,
      profesionalesAsignadosFilas,
      errors,
      cargaDetalle,
      crearServicioProgramado,
      tratamientos,
      tratamiento,
      nombre,
      apellidos,
      fechaNacimiento,
      lugarPrestacion,
      lugarEspacioColectivo,
      otrosEspacioColectivo,
      direccion,
      descripcion,
      inicioPrevisto,
      finPrevisto,
      inicioPrevistoFormateado: computed(() => {
        return moment(inicioPrevisto.value).format("D/M/Y");
      }),
      finPrevistoFormateado: computed(() => {
        return moment(finPrevisto.value).format("D/M/Y");
      }),
      tiempos,
      familiaId,
      comarcaId: comarcaIdV,
      comarcas,
      lugaresPrestacion,
      lugaresEspaciosColectivos,
      estado,
      enableTabProfesional: computed(() => {
        return fechas?.value?.length > 0 && fechasModificadas.value == false
          ? true
          : false;
      }),
      fechas: computed(() => {
        const resultado = [...(fechas?.value ?? [])];
        return resultado.sort((a, b) =>
          a.fecha > b.fecha ? 1 : b.fecha > a.fecha ? -1 : 0
        );
      }),
      dias,
      horarios,
      drawer,
      busquedaProfesional,
      fechasPrevistas: computed({
        get(): Date[] {
          if (!inicioPrevisto.value || !finPrevisto.value) return [];

          return [inicioPrevisto.value, finPrevisto.value];
        },
        set(newValue: Date[]) {
          if (newValue.length != 2) return;

          const [inicio, fin] = newValue;
          setInicioPrevisto(inicio);
          setFinPrevisto(fin);
        },
      }),
      addHorario: () => {
        if (!tiempos.value) {
          tiempos.value = [];
        }
        tiempos.value.push({ dias: [], horas: [] });
      },
      tabClick: async (tab) => {
        if (tab.paneName === "profesionales") {
          cargaListadoProfesionales.value = true;
          try {
            await module.fetchServicioProfesionales({
              comarcaId: comarcaId,
              id: token as string,
            });
          } catch (err) {
            Swal.fire({
              text: t("formularios.profesionalesListado.cargar.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } finally {
            cargaListadoProfesionales.value = false;
          }
        }
      },
      removeHorario(index) {
        if (tiempos.value.length > 1) {
          tiempos.value.splice(index, 1);
        } else {
          tiempos.value = [{ dias: [], horas: [] }];
        }
      },
      borrarFecha(index) {
        fechas.value.splice(index, 1);
        fechasModificadas.value = true;
      },
      generarFechas() {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        // Mostrar aviso especial de si hay profesional asignado, que se va a perder la configuracion
        if (profesionalesAsignados.value) {
          swalWithBootstrapButtons
            .fire({
              title: t(
                "dialogs.modificarNuevasFechasServicioConProfesional.title"
              ),
              width: 600,
              html: t(
                "dialogs.modificarNuevasFechasServicioConProfesional.body"
              ),
              icon: "question",
              showCancelButton: true,
              confirmButtonText: t(
                "dialogs.modificarNuevasFechasServicioConProfesional.okButton"
              ),
              cancelButtonText: t("dialogs.cancelButton"),
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                fechasModificadas.value = true;
                var d = moment(inicioPrevisto.value);
                var dDay = d.clone().hour(0).minutes(0);
                var until = moment(finPrevisto.value).endOf("day");
                const toInsert: Reserva[] = [];

                for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
                  let bloquesDias = tiempos.value?.map((t) => t.dias);
                  let bloquesHoras = tiempos.value?.map((t) => t.horas);
                  if (bloquesDias) {
                    bloquesDias.forEach(function (bloqueDia, index) {
                      let bloqueDiaArray = Object.values(bloqueDia);
                      if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                        for (let hora of bloquesHoras[index]) {
                          let fecha = moment(dDay).add(hora, "hours").format();
                          let row = {
                            fechaToShow: dDay.format("D/M/Y"),
                            fecha: fecha,
                            diaSemana: dDay.locale("es").format("dddd"),
                            hora: horarios.filter((h) => h.id == hora)[0][
                              "text"
                            ],
                          };
                          toInsert.push(row);
                        }
                      }
                    });
                  }
                }
                fechas.value = toInsert;
              }
            });
        }
        // Mostrar aviso especial de si hay fechas.length > 0 se va perder la configuracion
        if (
          fechas?.value?.length > 0 &&
          profesionalesAsignados.value == false
        ) {
          swalWithBootstrapButtons
            .fire({
              title: t("dialogs.modificarNuevasFechasServicio.title"),
              html: t("dialogs.modificarNuevasFechasServicio.body"),
              width: 600,
              icon: "question",
              showCancelButton: true,
              confirmButtonText: t(
                "dialogs.modificarNuevasFechasServicio.okButton"
              ),
              cancelButtonText: t("dialogs.cancelButton"),
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                fechasModificadas.value = true;
                var d = moment(inicioPrevisto.value);
                var dDay = d.clone().hour(0).minutes(0);
                var until = moment(finPrevisto.value).endOf("day");
                const toInsert: Reserva[] = [];

                for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
                  let bloquesDias = tiempos.value?.map((t) => t.dias);
                  let bloquesHoras = tiempos.value?.map((t) => t.horas);
                  if (bloquesDias) {
                    bloquesDias.forEach(function (bloqueDia, index) {
                      let bloqueDiaArray = Object.values(bloqueDia);
                      if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                        for (let hora of bloquesHoras[index]) {
                          let fecha = moment(dDay).add(hora, "hours").format();
                          let row = {
                            fechaToShow: dDay.format("D/M/Y"),
                            fecha: fecha,
                            diaSemana: dDay.locale("es").format("dddd"),
                            hora: horarios.filter((h) => h.id == hora)[0][
                              "text"
                            ],
                          };
                          toInsert.push(row);
                        }
                      }
                    });
                  }
                }
                fechas.value = toInsert;
              }
            });
        }
        // Si no tenía fechas ni profesional, no mostrar aviso y generar Fechas
        if (
          profesionalesAsignados.value == false &&
          (typeof fechas?.value?.length === "undefined" ||
            fechas?.value?.length == 0)
        ) {
          fechasModificadas.value = true;
          var d = moment(inicioPrevisto.value);
          var dDay = d.clone().hour(0).minutes(0);
          var until = moment(finPrevisto.value).endOf("day");
          const toInsert: Reserva[] = [];

          for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
            let bloquesDias = tiempos.value?.map((t) => t.dias);
            let bloquesHoras = tiempos.value?.map((t) => t.horas);
            if (bloquesDias) {
              bloquesDias.forEach(function (bloqueDia, index) {
                let bloqueDiaArray = Object.values(bloqueDia);
                if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                  for (let hora of bloquesHoras[index]) {
                    let fecha = moment(dDay).add(hora, "hours").format();
                    let row = {
                      fechaToShow: dDay.format("D/M/Y"),
                      fecha: fecha,
                      diaSemana: dDay.locale("es").format("dddd"),
                      hora: horarios.filter((h) => h.id == hora)[0]["text"],
                    };
                    toInsert.push(row);
                  }
                }
              });
            }
          }
          fechas.value = toInsert;
        }
      },

      onSubmit: handleSubmit(async (values) => {
        const resultante = {
          apellidos: values.apellidos,
          comarcaId: values.comarcaId,
          descripcion: values.descripcion,
          direccion: values.direccion,
          familiaId: values.familiaId,
          fechaNacimiento: values.fechaNacimiento,
          fechas: fechas.value,
          finPrevisto: values.finPrevisto,
          inicioPrevisto: values.inicioPrevisto,
          lugarEspacioColectivo: values.lugarEspacioColectivo,
          lugarPrestacion: values.lugarPrestacion,
          nombre: values.nombre,
          tratamiento: values.tratamiento,
          otrosEspacioColectivo: values.otrosEspacioColectivo,
          tiempos: values.tiempos,
        };
        crearServicioProgramado.value = true;
        try {
          await module
            .update({
              comarcaId: comarcaId,
              id: token as string,
              data: resultante,
            })
            .then(() => {
              Swal.fire({
                text: t("listados.servicioDetalle.guardar.mensaje.exito"),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                // En este caso no volvemos al listado para poder modificar detalles del servicio en varias tabs y continuar la edición
                //router.replace("/listados/serviciossolicitados");
                fechasModificadas.value = false;
              });
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.servicioDetalle.guardar.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          crearServicioProgramado.value = false;
        }
      }),
      eliminar: () => {
        Swal.fire({
          text: t("dialogs.eliminar.confirmacion"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("dialogs.okButton"),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) return;

          cargaDetalle.value = true;
          return module
            .eliminar({
              comarcaId: datosServicio.value?.comarcaId as string,
              elementId: token as string,
            })
            .then(() => {
              router.replace("/listados/serviciossolicitados");
              ElMessage({
                message: t("dialogs.eliminar.exito"),
                type: "success",
              });
            })
            .catch((err) => {
              Swal.fire({
                text: t("dialogs.eliminar.error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              console.error(err);
            })
            .finally(() => {
              cargaDetalle.value = false;
            });
        });
      },
    };
  },
});
